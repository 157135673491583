import React from 'react'
import { Link } from 'gatsby'
import Layout from './components/layout'
import Head from './components/head'
import contactPageStyles from './contactPage.module.scss'

const Contact = () => {
  return (
    <Layout>
      <Head title="Contact"/>
      <div className={`container ${contactPageStyles.wrapper}`}>
        <p>I'd love to hear from you! Send me a message using the contact form below.</p>
        <form name="contact" method="post" data-netlify="true" onSubmit="submit" className={contactPageStyles.form}>
          <input type="hidden" name="form-name" value="contact" />
          <div className="form-group">
            <label htmlFor="contactFormMessage">Your Message</label>
            <textarea name="message" className="form-control" id="contactFormMessage" rows="3" placeholder="Enter your message here" ></textarea>
          </div>
          <div className="form-group">
            <label htmlFor="contactFormEmail">Email address</label>
            <input type="email" name="email" className="form-control" id="contactFormEmail" aria-describedby="emailHelp" placeholder="Enter email" />
            <small id="emailHelp" className="form-text text-muted">I'll never share your email with anyone else.</small>
          </div>
          <button type="submit" className="btn btn-primary">Submit</button>
        </form>
        <div className={contactPageStyles.linksWrapper}>
          <p>Back to site: <Link to='/'>Homepage</Link> | <Link to='/download'>Free Download</Link></p>
        </div>
      </div>
    </Layout>
  )
}

export default Contact